import { IStep } from './types.constants';

export let roadMapList: Array<IStep> =
    [
        {
            key: 'step-1',
            step: 1,
            active: true,
            title: 'Capítulo 1',
            subtitle: 'Introducción',
            paragraph: 'Introducción a la computación y la programación',
            image: 'assets/images/introduccion.svg',
            imageAlt: 'introduccion',
            class: 'roadmap-card-1'
        },
        {
            key: 'step-2',
            step: 2,
            active: false,
            title: 'Capítulo 2',
            subtitle: 'Estructuras iterativas y de selección',
            paragraph: 'Desarrollo del pensamiento lógico',
            image: 'assets/images/creative.svg',
            imageAlt: 'estructuras',
            class: 'roadmap-card-2'
        },
        {
            key: 'step-3',
            step: 3,
            active: false,
            title: 'Capítulo 3',
            subtitle: 'Procedimientos y funciones',
            paragraph: 'Creando algoritmos para la solución de problemas',
            image: 'assets/images/code.svg',
            imageAlt: 'funciones',
            class: 'roadmap-card-3'
        },
        {
            key: 'step-4',
            step: 4,
            active: false,
            title: 'Capítulo 4',
            subtitle: 'Programación Orientada a Objetos',
            paragraph: 'Entendiendo la programacion orientada a objetos',
            image: 'assets/images/oop.svg',
            imageAlt: 'orientado-a-objetos',
            class: 'roadmap-card-4'
        },
        {
            key: 'step-5',
            step: 5,
            active: false,
            title: 'Mercado laboral',
            subtitle: 'Capítulo 5',
            paragraph: 'Conociendo herramientas y tecnologías usadas en el mercado laboral actual',
            image: 'assets/images/mercado-laboral.svg',
            imageAlt: 'mercado-laboral',
            class: 'roadmap-card-4'
        },
        {
            key: 'step-6',
            step: 6,
            active: false,
            title: '¿Y ahora que sigue?',
            subtitle: 'Capítulo 5',
            paragraph: 'Recibe feedback personalizado, para que sepas como seguir en esta carrera una vez finalizado el curso',
            image: 'assets/images/holding-arrow.svg',
            imageAlt: 'feedbacks-consejos',
            class: 'roadmap-card-4'
        }
];
