import { COUNTRIES, Country } from "./types.constants";

export const AVAILABLE_COUNTRIES: Array<Country> = [
    {
        id: 'CL',
        name: COUNTRIES.CHILE
    },
    {
        id: 'VE',
        name: COUNTRIES.VENEZUELA
    },
    {
        id: 'OTRO',
        name: COUNTRIES.OTHER
    },
]