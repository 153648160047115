<swiper fxFlex="auto" [config]="config" [style.height.rem]="swiperHeight">
    <div *ngFor="let item of list; let index = index" class="swiper-slide cursor-pointer px-md-5">
        <span *ngIf="item.numberIcon" class="number-circle" [ngClass]="{'number-circle-first-element': index === 0, 'number-circle-last-element': index === list.length-1}">{{index+1}}</span>
        <img [src]="item.image" [alt]="item.imageAlt" class="d-block mx-auto" [class]="item.imgClasses" width="160" height="160">
        <div class="swiper-content">
            <h3 class="text-center h5">{{item.title}}</h3>
            <hr>
            <div class="d-flex justify-content-center">
                <p class="d-inline text-center">{{item.paragraph}}</p>
            </div>
        </div>
    </div>
</swiper>