<article class="nav-container" id="navbar">
<section class="container-fluid p-0">
  <nav class="navbar-light navbar-fixed-top small-nav" id="navbar-fixed">
    <!-- IMPORTANT INFORMATION -->
    <!-- <section class="container-important-info">
      <article class="text-center">
        <section class="my-1 d-none d-md-block"><span id="redirectToPayment">Curso: Primeros pasos en código / Próxima fecha: por definir</span></section>
        <section class="my-1 d-md-none"><span id="redirectToPayment">Inscribirme en Curso</span></section>
      </article>
    </section> -->
    <section class="navbar-content">
      <article class="navbar-brand" (click)="moveToTop()">
        <img src="assets/images/logo.png" alt="logo-programa-creativo" class="logo small-logo" id="logo-img"
        width="220" height="132">
      </article>
      <article class="content-questions d-none content-question-small-section d-lg-inline-block" id="content-question">
        <ul>
          <!-- <li class="mr-3">
            <div class="d-inline-block" (click)="moveToOtherSection(QUESTIONS.QUESTION_1)">
              <p class="question d-inline-block h6">
                ¿Cómo sé si este curso es para mi?
              </p>
              <span class="icon icon-arrow-down ml-2"></span>
            </div>
          </li>
          <li class="mr-3">
            <div class="ml-3 ml-lg-4 d-inline-block" (click)="redirectToRegisterForm()">
              <p class="question d-inline-block h6">
                Inscribirme en Curso
              </p>
              <span class="icon icon-new-window ml-2"></span>
            </div>
          </li> -->
          <li class="mr-3"> 
            <div class="ml-3 ml-lg-4 d-inline-block" (click)="goToYoutubeChannel()">
              <p class="question d-inline-block h6 align-middle">
                YouTube
              </p>
              <span class="icon icon-youtube ml-2"></span>
            </div>
          </li>
          <li>
            <div class="ml-3 ml-lg-4 d-inline-block" (click)="openNewTab(environment.blog.link)">
              <p class="question d-inline-block h6">
                Blog
              </p>
              <span class="icon icon-new-window ml-2"></span>
            </div>
          </li>
        </ul>
      </article>
      <button class="navbar-toggler d-lg-none navbar-toggler-small" id="navbar-toggle" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation"
              (click)="toggle()">
        <span class="navbar-toggler-icon"></span>
      </button>
    </section>
    <article class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav">
        <!-- <li class="nav-item">
          <a class="nav-link" (click)="moveToOtherSection(QUESTIONS.QUESTION_1)">
            ¿Cómo sé si este curso es para mi?
            <span class="icon icon-arrow-down ml-2"></span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="redirectToRegisterForm()">
            YouTube
            <span class="icon icon-new-window ml-2"></span>
          </a>
        </li> -->
        <li class="nav-item">
          <a class="nav-link" (click)="goToYoutubeChannel()">
            YouTube
            <span class="icon icon-youtube ml-2"></span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="openNewTab(environment.blog.link)">
            Blog Programa Creativo
            <span class="icon icon-new-window ml-2"></span>
          </a>
        </li>
      </ul>
    </article>
  </nav>
</section>
</article>