export interface IStep {
    key?: string;
    step: number;
    active: boolean;
    title: string;
    subtitle: string;
    paragraph: string;
    image?: string;
    imageAlt?: string;
    class?: string;
    numberIcon?: boolean;
    swiperHeight?: string;
    imgClasses?: string;
}

export enum STEP {
    STEP_1 = 'step-1',
    STEP_2 = 'step-2',
    STEP_3 = 'step-3',
    STEP_4 = 'step-4',
}

export interface ITeam {
    name: string;
    description: string;
    image: string;
    linkedinUrl: string;
}

export enum QUESTIONS {
    QUESTION_1 = 'question-1',
    QUESTION_2 = 'question-2',
    QUESTION_3 = 'question-3',
    QUESTION_4 = 'question-4',
    QUESTION_5 = 'question-5',
    QUESTION_6 = 'question-6',
    QUESTION_7 = 'question-7',
    QUESTION_8 = 'question-8',
    QUESTION_9 = 'question-9',
    QUESTION_10 = 'question-10',
    QUESTION_11 = 'question-11',
    QUESTION_12 = 'question-12',
    QUESTION_13 = 'question-13',
}

export interface IQuestion {
    question: string;
    answer: string;
    active?: boolean;
    id: string;
}

export interface IRecommendation {
    title: string;
    image: string;
    alt: string;
}

export interface IBenefits {
    title: string;
    paragraph: string;
    image: string;
    imageAlt: string;
    numberIcon?: boolean;
    swiperHeight?: string;
    imgClasses?: string;
}

export enum COUNTRIES {
    CHILE = 'CHILE',
    BRASIL = 'BRASIL',
    ESPANA = 'ESPANA',
    PANAMA = 'PANAMA',
    US = 'UNITED STATES',
    VENEZUELA = 'VENEZUELA',
    OTHER = 'OTRO'
}

export interface Country {
    id: string;
    name: string;
}
