import { Component } from '@angular/core';
import { IQuestion } from 'src/app/core/constants/types.constants';
import { faqList } from '../../../app/core/constants/faq.constants';

@Component({
  selector: 'app-faq-section',
  templateUrl: './faq-section.component.html'
})
export class FaqSectionComponent {
  faqList = faqList;
  constructor() { }

  changeActiveValue = (item: IQuestion) => {
    this.faqList.map(el => {
      if (item !== el) {
        el.active = false;
      }
    });
    item.active = !item.active;
  }

}
