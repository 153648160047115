import { ITeam } from './types.constants';

export let teamList: Array<ITeam> = [
    {
        name: '<strong>Juan Vásquez</strong>',
        description: '<strong>CTO CO-Fundador</strong> de Programa Creativo y Desarrollador Mobile, +6 años de experiencia',
        image: 'assets/images/juan.svg',
        linkedinUrl: 'https://www.linkedin.com/in/juan-vasquez-ferrer-108741116/'
    },
    {
        name: '<strong>Gabriel Gómez</strong>',
        description: '<strong>CEO CO-Fundador,</strong> de Programa Creativo y Desarrollador Frontend, +5 años de experiencia',
        image: 'assets/images/gabriel.svg',
        linkedinUrl: 'https://www.linkedin.com/in/gabriel-g%C3%B3mez-lemus-036389136/'
    },
    {
        name: '<strong>Nebraska Meléndez</strong>',
        description: '<strong>Directora académica</strong> y Desarrolladora Mobile, +6 años de experiencia',
        image: 'assets/images/nebraska.svg',
        linkedinUrl: 'https://www.linkedin.com/in/nebraska-melendez-753367113/'
    }
];
