<div class="container-fluid px-4 team my-5">
    <hr>
    <div class="row justify-content-center">
        <div class="col-12 mb-md-5">
            <h2 class="my-4 text-center">Nuestro equipo</h2>
            <p class="text-center">
                Estar aprendiendo constantemente y ayudar a otros, es parte de nuestra filosofía de vida.<br/>
                ¡Conoce de nuestras experiencias como desarrolladores, evita nuestros errores y aprovecha esta oportunidad al 100%!
            </p>
        </div>
        <div class="col-12 col-md-6 col-lg-4 col-xl-3" [ngClass]="{'offset-xl-1': i != 0}" *ngFor="let member of teamList; let i = index">
            <div class="memberContainer">
                <div class="d-block">
                    <img [src]="member.image" [alt]="member.name" class="d-block my-0 mx-auto p-1 position-relative" width="250" height="250"/>
                    <div class="icon position-absolute" (click)="openWindow(member.linkedinUrl)"></div>
                </div>
                <div class="d-block">
                    <p class="text-center" [innerHTML]="member.name"></p>
                    <p class="text-center" [innerHTML]="member.description"></p>
                </div>
            </div>
            <hr class="d-md-none">
        </div>
    </div>
</div>