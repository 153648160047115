<div class="container-fluid px-0 px-md-4 footer">
    <section class="row">
        <article class="col-12 col-md-4 col-lg-3">
            <img src="assets/images/white-logo.png" alt="isotipo-programa-creativo" class="logo"
            width="220" height="148">
        </article>
        <article class="col-12 col-md-8 col-lg-9">
            <section class="row">
                <section class="col-12 col-md-6 col-lg-6">
                    <ul>
                        <li>
                            <div class="font-weight-bold">
                                Teléfonos:
                            </div>
                        </li>
                        <li>
                            <span>+58 412 973 5278</span>
                        </li>
                    </ul>
                </section>
                
                <section class="col-12 col-md-6 col-lg-6">
                    <ul class="social-networks">
                        <li class="li-title mb-2">
                            <div class="font-weight-bold">
                                Nuestras redes:
                            </div>
                        </li>
                        <li class="li-sn mr-2 d-inline-block" (click)="openWindow('https://www.instagram.com/programacreativo/?hl=es-la')">
                            <div class="icon-container">
                                <img src="assets/images/instagram-white-icon-image.svg" alt="ig-icon-programa-creativo" class="icon-sn"
                                width="20" height="20">
                            </div>
                        </li>
                        <li class="li-sn mr-2 d-inline-block" (click)="openWindow('https://www.facebook.com/programacreativo')">
                            <div class="icon-container">
                                <img src="assets/images/facebook-app-symbol.png" alt="fb-icon-programa-creativo" class="icon-sn"
                                width="20" height="20">
                            </div>
                        </li>
                        <li class="li-sn d-inline-block" (click)="openWindow('https://www.linkedin.com/company/80068203/admin/')">
                            <div class="icon-container">
                                <img src="assets/images/linkedin-white-icon-image.svg" alt="in-icon-programa-creativo" class="icon-sn"
                                width="20" height="20">
                            </div>
                        </li>
                    </ul>
                </section>
                <section class="col-12 col-md-6 col-lg-6">
                    <ul>
                        <li>
                            <div class="font-weight-bold">
                                Dirección:
                            </div>
                        </li>
                        <li>
                            <span>
                                Santiago, Chile.
                            </span>
                        </li>
                    </ul>
                </section>
                <section class="col-12 col-md-6 col-lg-6">
                    <ul>
                        <li>
                            <div class="font-weight-bold">
                                Términos y condiciones
                            </div>
                        </li>
                        <li>
                            <span>
                                <a href="https://github.com/programacreativo/community/blob/main/terms.md" target="_blank">Conoce nuestros términos y condiciones</a>
                            </span>
                        </li>
                    </ul>
                </section>
            </section>
            <section class="row">
                <div class="col-12">
                    <ul>
                        <li>
                            <div class="font-weight-bold">
                                Correo electrónico:
                            </div>
                        </li>
                        <li class="email">
                            <span>
                                programacreativo.academy@gmail.com
                            </span>
                        </li>
                    </ul>
                </div>
            </section>
        </article>
    </section>
</div>