import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-up.component.html'
})
export class PopUpComponent implements OnInit {
  closeButtonActive = true;
  registerToMasterClassForm: string = environment.sdk.registerToMasterClassForm;

  constructor() { }

  ngOnInit(): void {
    this.showPopup();
  }

  redirectToRegisterMaterClassView = () => {
    window.open(this.registerToMasterClassForm, '_self');
  }

  redirectToRegisterCourseView = () => {
    window.open(environment.sdk.registerForm, '_self');
  }

  showPopup = () => {
    window.addEventListener('scroll', () => {
      const y = window.scrollY;
      if (y > 250 && this.closeButtonActive) {
        document.getElementById('popup').classList.add('showPopup');
      } else {
        document.getElementById('popup').classList.remove('showPopup');
      }
    });
  }

  closeButton = () => {
    document.getElementById('popup').classList.remove('showPopup');
    this.closeButtonActive = false;
  }

}
