import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent {
  lottieConfig = {
    path: 'assets/json/coding.json',
  };
  registerForm: string = environment.sdk.registerForm;
  constructor() { }


  redirectToRegisterView = () => {
    window.open(this.registerForm, '_self');
  }
}
