// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  sdk: {
    hotjar: {
      key: '2828282'
    },
    google: {
      analytics: {
        key: 'G-R530KZ4RGB'
      },
      gtm: {
        key: 'GTM-55DK9PG'
      }
    },
    flow: {
      payLink: 'https://www.flow.cl/uri/p57d2WQkq'
    },
    whatsapp: {
      link: 'https://wa.link/jag2x4'
    },
    payment: 'https://payment.programacreativo.com/PaymentFlow',
    registerForm: 'https://curso.programacreativo.com/registro',
    registerToMasterClassForm: 'https://curso.programacreativo.com/registro-masterclass',
  },
  youtubeChannel: 'https://www.youtube.com/@programacreativo3172',
  blog: {
    link: 'https://programa-creativo.blogspot.com/2022/10/como-puedo-saber-si-la-programacion-es.html'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
