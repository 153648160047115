export interface PAYMENT_METHOD {
    id?: string;
    name: string;
    info: string;
}


export const PAYMENT_METHODS: Array<PAYMENT_METHOD> = [
    {
        name: 'Binance Pay',
        info: '<p><strong>Correo</strong>: gabrielgomez353@gmail.com<br/><br/><strong>Importante:</strong> Para procesar tu inscripción envía el comprobante al correo <strong>programacreativo.academy@gmail.com</strong>.</p>'
    },
    {
        name: 'Paypal',
        info: '<p><strong>Correo</strong>: gabrielgomez353@gmail.com<br/><br/><strong>Importante:</strong> Para procesar tu inscripción envía el comprobante al correo <strong>programacreativo.academy@gmail.com</strong>.<p>'
    },
    {
        id: 'INTERNATIONAL_CARD',
        name: 'Tarjeta internacional',
        info: '<p>Al hacer clic en el botón de abajo te vamos a <strong>redirigir a la pestaña de flow.cl</strong> en donde tendrás que <strong>ingresar tu correo</strong> y proceder a pagar, para así formalizar tu inscripción.</p><br/><p><strong>Importante:</strong> este precio se va a ver reflejado en pesos chilenos. Exactamente <strong>67.999 pesos chilenos</strong>. Sin embargo cuando se realice el pago se va a ver en su cuenta debitado en dólares. <strong>85.68$.</strong></p>'
    },
]

export const PAYMENT_METHODS_VE: Array<PAYMENT_METHOD> = [
    {
        name: 'Pago móvil',
        info: '<p><strong>Monto: 85,68$ (tasa del BCV)</strong><br/><br/>Para procesar tu inscripción envía el comprobante de pago al correo <strong>programacreativo.academy@gmail.com</strong> o al <strong>Whatsapp: +56927015430</strong>:</p><ul><li><strong>Número de teléfono:</strong> 0412 973 5278</li><li><strong>Banco:</strong> Banesco</li><li><strong>Cédula:</strong> 26530267</li></ul>'
    },
    {
        name: 'Transferencia bancaria',
        info: '<p><strong>Monto: 85,68$ (tasa del BCV)</strong><br/><br/>Para procesar tu inscripción envía el comprobante de pago al correo <strong>programacreativo.academy@gmail.com</strong> o al <strong>Whatsapp: +56927015430</strong>:</p><ul><li><strong>Número de cuenta:</strong> 0134 0251 5025 1102 8653</li><li><strong>Banco:</strong> Banesco</li><li><strong>Cuenta:</strong> Corriente</li><li><strong>Nombre:</strong> Gabriel Gomez</ul>'
    },
    {
        name: 'Binance Pay',
        info: '<p><strong>Monto: 85,68$</strong><br/><br/><strong>Correo</strong>: gabrielgomez353@gmail.com<br/><br/><strong>Importante:</strong> Para procesar tu inscripción envía el comprobante al correo <strong>programacreativo.academy@gmail.com</strong></p>'
    },
    {
        name: 'Paypal',
        info: '<p><strong>Monto: 85,68$</strong><br/><br/><strong>Correo</strong>: gabrielgomez353@gmail.com<br/><br/><strong>Importante:</strong> Para procesar tu inscripción envía el comprobante al correo <strong>programacreativo.academy@gmail.com</strong><p>'
    },
    {
        id: 'INTERNATIONAL_CARD',
        name: 'Tarjeta internacional',
        info: '<p><strong>Monto: 85,68$ (tasa del BCV)</strong><br/><br/>Al hacer clic en el botón de abajo te vamos a <strong>redirigir a la pestaña de flow.cl</strong> en donde tendrás que <strong>ingresar tu correo</strong> y proceder a pagar, para así formalizar tu inscripción.</p><br/><p><strong>Importante:</strong> este precio se va a ver reflejado en pesos chilenos. Exactamente <strong>67.999 pesos chilenos</strong>. Sin embargo cuando se realice el pago se va a ver en su cuenta debitado en dólares. <strong>85.68$</strong></p>'
    },
]