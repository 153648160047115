import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-price-section',
  templateUrl: './price-section.component.html'
})
export class PriceSectionComponent implements OnInit {
  registerForm: string = environment.sdk.registerForm;
  constructor() { }

  ngOnInit(): void {
  }

  redirectToPaymentView() {
    window.open(this.registerForm, '_self');
  }
}
