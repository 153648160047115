<div class="container-fluid" id="header">
  <section class="row header px-0 justify-content-center">
    <!-- Contenedor del video y texto -->
    <div
      class="position-relative d-flex align-items-center justify-content-center view-height w-100"
    >
      <!-- Video de fondo -->
      <video
        class="position-absolute w-100 h-100 object-fit-cover opacity-40"
        autoplay
        loop
        muted
      >
        <source src="assets/videos/online-class.mp4" type="video/mp4" />
      </video>

      <!-- Texto en primer plano -->
      <div class="position-relative z-index-1 main-content">
        <h1 class="display-4 text-center main-title">
          <span class="font-weight-bold px-4 text-center mx-auto d-none d-md-block">
            Desarrolla tu pensamiento lógico, programa y cumple tus metas profesionales
          </span>
          <!-- mobile text -->
          <h3 class="font-weight-bold px-4 text-center mx-auto d-md-none text-small">
            Desarrolla tu pensamiento lógico, programa y cumple tus metas profesionales
          </h3>
        </h1>
        <!-- <p class="text-center main-paragraph mx-4">
          Desarrolla tu pensamiento lógico, programa y cumple tus metas profesionales
        </p> -->
        <!-- <button
          type="button"
          class="btn-go-to-next-section py-3 px-5 mt-3 mt-md-5 mx-auto"
          (click)="redirectToRegisterView()"
        >
          <span class="mx-auto"> Inscribirme en el <strong>Curso</strong></span>
          <span class="icon icon-register my-2 ml-4 d-none d-md-block"></span>
        </button> -->
      </div>
      <!-- <img src="assets/images/down-arrow.png" alt="down-arrow" width="40" height="40" class="mx-auto d-block mb-0"> -->
    </div>
  </section>
  <article class="row justify-content-center text-center mt-5 py-3">
    <section class="col col-lg-8">
      <h2 id="benefits-main-title">
        Nuestro plan de carrera te da
        <strong>herramientas</strong> y te prepara
        <strong> para resolver problemas</strong> reales del mundo de la
        <strong>programación</strong>
      </h2>
    </section>
  </article>
</div>
