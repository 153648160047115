import { IQuestion, QUESTIONS } from './types.constants';


export let faqList: Array<IQuestion> = [
    {
        question: '¿Cómo sé si este curso es para mí?',
        answer: 'Si tienes <strong>afinidad y te gusta la tecnología</strong> pero no tienes idea de como adentrarte en el mundo de la programación, <strong>este curso es para ti</strong>. Nuestro plan de carrera te ofrece la oportunidad de adquirir las <strong> habilidades, herramientas y técnicas </strong> necesarias para <strong>abordar y resolver problemas </strong> reales del mundo de la programación.',
        active: false,
        id: QUESTIONS.QUESTION_1
    },
    {
        question: '¿Cómo puedo entrar en el próximo curso?',
        answer: '<span>Registrándote en el siguiente link: <strong><a href="https://curso.programacreativo.com/registro" class="cursor-pointer">haz clic aquí para registrarte</a> <strong>',
        active: false,
        id: QUESTIONS.QUESTION_4
    },
    {
        question: 'Si no quedo satisfecho/a con el curso, ¿puedo pedir un reembolso?',
        answer: '<span>Sí. Si no quedaste satisfecho/a con el curso, puedes pedir un <strong>reembolso del 100%</strong> de lo que pagaste. Sin ningún tipo de compromiso.</strong></span>',
        active: false,
        id: QUESTIONS.QUESTION_3
    },
    {
        question: '¿Qué necesito para tomar el curso?',
        answer: '<span><strong>Un computador con conexión a internet</strong>(laptop o pc).</span>',
        active: false,
        id: QUESTIONS.QUESTION_13
    },
    {
        question: '¿Cómo serán las clases del curso?',
        answer: '<span>Las clases del curso serán por videollamadas en <strong>Google Meet</strong>.</span>',
        active: false,
        id: QUESTIONS.QUESTION_5
    },
    {
        question: 'Si falto a una clase, ¿puedo continuar el curso?',
        answer: `<span><strong>Sí. Todas las clases van a quedar grabadas</strong> y se subirán a una plataforma para que puedas acceder al contenido ya sea 
        para recuperar una clase perdida, o reforzar el conocimiento.</span>`,
        active: false,
        id: QUESTIONS.QUESTION_6
    },
    {
        question: '¿Cuánto dura el curso?',
        answer: '<span>El curso tendrá una duración de <strong>seis semanas</strong>. Durante este lapso de tiempo veremos todo el contenido del curso.</span>',
        active: false,
        id: QUESTIONS.QUESTION_7
    },
    {
        question: '¿Qué aprenderé durante la formación?',
        answer: '<span>Aprenderás sobre <strong>algoritmos, programación, tipo de datos, estructura de selección, estructura de iteración, arreglos, funciones, procedimientos y Programación Orientada a Objetos.</strong></span>',
        active: false,
        id: QUESTIONS.QUESTION_8
    },
    {
        question: '¿Para qué me servirán estos conocimientos?',
        answer: '<span>Al terminar el curso <strong>tendrás herramientas necesarias y conocimientos sólidos para continuar tu formación en el mundo de la programación</strong>, y puedas aprender tecnologías aplicables al mercado laboral actual.</span>',
        active: false,
        id: QUESTIONS.QUESTION_9
    },
    {
        question: 'Aún tengo dudas, ¿cómo puedo contactarlos para tener más información del curso?',
        answer: 'Puedes comunicarte con nosotros a través de <strong>Whatsapp</strong> haciendo clic en el botón que se encuentra abajo a la derecha <img src="assets/images/whatsapp-image.svg" width="30"/> o por medio de correo electrónico: <strong>programacreativo.academy@gmail.com</strong>.',
        active: false,
        id: QUESTIONS.QUESTION_10
    },
    {
        question: '¿Cómo puedo pagar en otra moneda?',
        answer: 'Puedes comunicarte con nosotros a través de <strong>Whatsapp</strong> haciendo clic en el botón que se encuentra abajo a la derecha <img src="assets/images/whatsapp-image.svg" width="30"/> o por medio de correo electrónico: <strong>programacreativo.academy@gmail.com</strong> para facilitarte otro medio de pago.',
        active: false,
        id: QUESTIONS.QUESTION_11
    },
    {
        question: '¿El curso es certificado?',
        answer: 'En la actualidad, no proporcionamos una certificación oficial. Sin embargo, nuestro enfoque principal es equiparte con habilidades técnicas sólidas en programación y fomentar tu pensamiento lógico. Creemos que estas habilidades son fundamentales para sobresalir en el mercado laboral actual. Además te proporcionamos las herramientas necesarias para destacarte y aprovechar al máximo recursos especializados, lo que aumentará significativamente tus posibilidades de encontrar oportunidades laborales y avanzar en tu carrera.',
        active: false,
        id: QUESTIONS.QUESTION_12
    }
];
