import { Component, OnInit } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { BENEFITS } from 'src/app/core/constants/benefits.constants';
import { SWIPER_ROADMAP_CONFIG } from 'src/app/core/constants/swiper.constants';
import { IBenefits } from 'src/app/core/constants/types.constants';

@Component({
  selector: 'app-benefits',
  templateUrl: './benefits.component.html'
})
export class BenefitsComponent implements OnInit {
  benefits: Array<IBenefits> = BENEFITS;
  swiperConfig: SwiperConfigInterface = SWIPER_ROADMAP_CONFIG;
  swiperHeight: number = 20;
  constructor() { }

  ngOnInit(): void {
  }

}
