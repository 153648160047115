import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { PaymentViewComponent } from './payment-view/payment-view.component';
import { ViewsRoutingModule } from './views-routing.module';


@NgModule({
  imports: [
    CommonModule,
    ViewsRoutingModule,
    RouterModule,
    MatSelectModule,
    FormsModule,
		ReactiveFormsModule,
  ],
  providers: [],
  bootstrap: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  declarations: [
    PaymentViewComponent
  ]
})
export class ViewsModule { }
