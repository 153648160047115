<section id="faqSection" class="mb-6"></section>
<article class="container-fluid px-4 faqSection mt-6">
    <section class="row justify-content-center">
        <article class="col-12">
            <h2 class="mb-4 text-center">Preguntas Frecuentes</h2>
        </article>
        <article class="col-12 col-md-10 col-lg-7">
            <section id="accordion">
                <article class="card card-collapse" *ngFor="let item of faqList; let i = index">
                    <section class="card-header" [ngClass]="{'card-header-last': i === faqList.length-1}" [id]="item.id"
                        data-toggle="collapse" attr.data-target="collapse_{{i}}" [aria-expanded]="item.active"
                        attr.aria-controls="collapse_{{i}}" (click)="changeActiveValue(item)">
                        <button class="btn d-inline-block" [innerHTML]="item.question">
                        </button>
                        <img src="assets/images/down-arrow-image.svg" alt="down-arrow-programa-creativo" class="down-arrow" width="23" height="36">
                    </section>
                    <section id="collapse_{{i}}'" class="collapse" [ngClass]="{'show':item.active}"
                        attr.aria-labelledby="collapse_{{i}}" data-parent="#accordion">
                        <article class="card-body" [ngClass]="{'card-body-last': i === faqList.length-1, 'active': item.active}" [innerHTML]="item.answer">
                        </article>
                    </section>
                </article>
            </section>
        </article>
    </section>
</article>