import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { AnimationLoader, LottieModule } from 'ngx-lottie';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { CoreModule } from '../core/core.module';
import { BenefitsComponent } from './benefits/benefits.component';
import { FaqSectionComponent } from './faq-section/faq-section.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { NavbarComponent } from './navbar/navbar.component';
import { PopUpComponent } from './pop-up/pop-up.component';
import { PriceSectionComponent } from './price-section/price-section.component';
import { RecommendationSectionComponent } from './recommendation-section/recommendation-section.component';
import { RoadmapComponent } from './roadmap/roadmap.component';
import { SwiperComponent } from './swiper/swiper.component';
import { TeamComponent } from './team/team.component';
import { WarrantyComponent } from './warranty/warranty.component';

export function playerFactory(): any {
    return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

const sharedComponents = [
    FooterComponent,
    HeaderComponent,
    RoadmapComponent,
    RecommendationSectionComponent,
    WarrantyComponent,
    TeamComponent,
    FaqSectionComponent,
    NavbarComponent,
    BenefitsComponent,
    PriceSectionComponent,
    PopUpComponent
];

@NgModule({
    declarations: [
        ...sharedComponents,
        SwiperComponent,
    ],
    exports: [
        ...sharedComponents
    ],
    imports: [
        CoreModule,
        SwiperModule,
        LottieModule.forRoot({ player: playerFactory })
    ],
    providers: [AnimationLoader],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class SharedModule { }
