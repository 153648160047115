import { Component } from '@angular/core';

@Component({
  selector: 'app-warranty',
  templateUrl: './warranty.component.html'
})
export class WarrantyComponent {
  lottieConfig = {
    path: 'assets/json/5-stars.json',
  };
  styles: Partial<CSSStyleDeclaration> = {
    display: 'block',
    margin: '0 auto',
  };
  constructor() { }
}
