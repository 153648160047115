import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {
  title = 'programa-creativo-web';
  constructor() {
      // this.cookieService.set('SameSite', 'Secure');
    }

    ngOnInit() {
    }
}
