import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { QUESTIONS } from '../../../app/core/constants/types.constants';
import { faqList } from '../../../app/core/constants/faq.constants';
import { environment } from "../../../environments/environment"
import { ViewportScroller } from '@angular/common';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})
export class NavbarComponent implements OnInit {
  environment = environment;
  faqList = faqList;
  paymentUrl: any = environment.sdk.flow.payLink;
  toggleActive = false;
  navbar = document.getElementById('navbar-fixed');
  logo = document.getElementById('logo-img');
  sectionQuestions = document.getElementById('content-question');
  navbarToogle = document.getElementById('navbar-toggle');
  QUESTIONS = QUESTIONS;
  registerForm: string = environment.sdk.registerForm;
  registerToMasterClassForm: string = environment.sdk.registerToMasterClassForm;
  youtubeChannel: string = environment.youtubeChannel;

  constructor(private readonly router: Router, private viewportScroller: ViewportScroller) { }

  ngOnInit(): void {
  }

  goToYoutubeChannel = () => {
    window.open(this.youtubeChannel, '_blank');
  }

  redirectToRegisterForm = () => {
    window.open(this.registerForm, '_self');
  }

  redirectToRegisterMasterClassForm = () => {
    window.open(this.registerToMasterClassForm, '_self');
  }

  redirect = (url: string) => {
    this.router.navigate([url]);
  }

  openNewTab = (url: string) => {
    window.open(url, '_blank')
  }

  resizeNavbar(): void {
    window.addEventListener('scroll', () => {
      this.showHideNavElements();
    });
  }

  moveToTop(): void {
    this.viewportScroller.scrollToAnchor('navbar');
  }

  moveToOtherSection(questionId: string): void {
    this.closeDropdown();
    this.changeActiveValue(questionId);
    this.viewportScroller.scrollToAnchor('faqSection');
  }

  closeDropdown = () => {
    const navbarMobile = document.getElementById('navbarNavDropdown');
    navbarMobile.classList.remove('show');
    document.getElementById('navbar-fixed').classList.remove('navbar-toggler-content');
    this.toggleActive = false;
    navbarMobile.classList.remove('d-block');
  }

  changeActiveValue = (questionId: string) => {
    this.faqList.map(el => {
      el.active = false;
    });
    faqList.find(e => e.id === questionId ? e.active = true : e.active = false);
  }

  toggle(): void {
    const navbar = document.getElementById('navbar-fixed');
    const navbarMobile = document.getElementById('navbarNavDropdown');
    const navbarToogle = document.getElementById('navbar-toggle');
    this.toggleActive = !this.toggleActive;
    if (this.toggleActive) {
      document.getElementById('navbar-fixed').classList.add('navbar-toggler-content');
      // navbar.classList.remove('small-nav');
      navbarToogle.classList.remove('navbar-toggler-small');
      navbarMobile.classList.add('d-block');
    } else {
      navbarMobile.classList.remove('d-block');
      document.getElementById('navbar-fixed').classList.remove('navbar-toggler-content');
    }
  }

  showHideNavElements(): void {
    const y = window.scrollY;
    const navbar = document.getElementById('navbar-fixed');
    const logo = document.getElementById('logo-img');
    const sectionQuestions = document.getElementById('content-question');
    const navbarToogle = document.getElementById('navbar-toggle');
    if (y > 10 && !this.toggleActive) {
      navbar.classList.add('small-nav');
      sectionQuestions.classList.add('content-question-small-section');
      navbarToogle.classList.add('navbar-toggler-small');
      logo.classList.add('small-logo');
    }
  }
}
