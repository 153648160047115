import { Component, OnInit } from '@angular/core';
import { RECOMMENDATIONS } from 'src/app/core/constants/recommendations.constants';
import { IRecommendation } from 'src/app/core/constants/types.constants';

@Component({
  selector: 'app-recommendation-section',
  templateUrl: './recommendation-section.component.html'
})
export class RecommendationSectionComponent implements OnInit {
  recommendationsInfo: Array<IRecommendation> = RECOMMENDATIONS;
  constructor() { }

  ngOnInit(): void {
  }

}
