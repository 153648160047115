import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { GTMScripts } from './app/core/scripts/common-analytics.script';
import { environment } from './environments/environment';

if (environment.production) {
  GTMScripts(environment.sdk.google.gtm.key);
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
