<!-- WARRANTY -->
<div class="container-fluid px-4 my-4">
    <div class="row">
        <div class="col-12 warranty">
            <hr class="mb-md-5">
            <h2 class="text-center my-md-5">Queremos que estés tranquilo/a con tu compra</h2>
            <div class="row justify-content-center">
                <div class="col-12 col-md-6 align-self-center pl-lg-5">
                    <div class="pl-lg-5">
                        <h3 class="main-text">
                            -<br/>
                            Si no quedaste satisfecho/a con el curso, puedes pedir un <strong>reembolso del 100%</strong> de lo que pagaste sin
                            compromiso.
                        </h3>
                        <ng-lottie
                            width="250px"
                            height="150px"
                            [options]="lottieConfig"
                            containerClass="d-block mx-auto mr-md-auto d-md-inline-block"
                        ></ng-lottie>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <img src="assets/images/quality.svg" alt="quality-programa-creativo" class="main-img d-block my-0 mx-auto" width="300" height="276">
                </div>
            </div>
        </div>
    </div>
</div>