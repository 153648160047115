import { Component, Input } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { IBenefits, IStep } from 'src/app/core/constants/types.constants';

@Component({
  selector: 'app-swiper',
  templateUrl: './swiper.component.html'
})
export class SwiperComponent {
  @Input() list: Array<IStep | IBenefits>;
  @Input() config: SwiperConfigInterface;
  @Input() swiperHeight: number = 22;
  constructor() { }

}
