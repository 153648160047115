<section
class="d-flex flex-column justify-content-center align-items-center vh-100"
>
<section>
  <img src="assets/images/logo.png" alt="logo" width="190" height="180" (click)="goHome()">
</section>
<article class="container">
  <section class="col-12">
    <article class="mb-5">
      <h4 class="text-center">¡Ya casi eres parte de esta experiencia! 🤓</h4>
    </article>
    <!-- BTN GO BACK -->
    
    <button class="btn goBack" (click)="goBack()" *ngIf="form.get('country').value">
      <img src="assets/images/back.png" alt="go-back">
    </button>
    <!-- COUNTRY FORM -->
    <form [formGroup]="form" *ngIf="!form.get('country').value" class="text-center">
      <!-- COUNTRY SELECT -->
      <mat-form-field class="select-country">
        <mat-label>Elige tu país de residencia</mat-label>
        <mat-select formControlName="country" required>
          <mat-option *ngFor="let country of countries" [value]="country" (click)="country.name !== COUNTRIES.CHILE ? initPaymentMethodForm() : null">
            {{ country.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
    <!-- CHILEAN PAYMENT METHODS -->
    <article *ngIf="form.get('country').value?.name === COUNTRIES.CHILE" class="mt-4 payment-method-content">
      <section>
        <p>
          Monto:<strong> 67.999 CLP </strong><br/><br/>
          Al hacer clic en el botón de abajo te vamos a <strong>redirigir a la pestaña de flow.cl</strong> en donde tendrás que <strong>ingresar tu correo</strong> y proceder a pagar, para así formalizar tu inscripción.
        </p>
        <button class="btn btn-success mx-auto d-block" (click)="redirectToPaymentLink()">Comprar curso</button>
      </section>
    </article>
    <!-- VENEZUELAN PAYMENT METHODS -->
    <article *ngIf="form.get('country').value?.name === COUNTRIES.VENEZUELA" class="mt-4 payment-method-content">
      <section>
        <p>
          Para proceder con tu compra del curso <strong>elige la opción de pago</strong> que más te
          convenga:
        </p>
        <!-- VENEZUELAN PAYMENT OPTIONS -->
        <form [formGroup]="paymentMethodForm">
          <mat-form-field class="select-ve-payment">
            <mat-label>Método de pago</mat-label>
            <mat-select formControlName="paymentMethod" required>
              <mat-option *ngFor="let method of paymentMethodsVe" [value]="method">
                {{ method.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
        <article class="payment-info" [innerHTML]="paymentMethodForm.get('paymentMethod').value?.info"></article>
        <!-- BUTTON FOR INTERNATIONAL PAYMENT -->
        <button *ngIf="paymentMethodForm.get('paymentMethod').value?.id === 'INTERNATIONAL_CARD'" class="btn btn-success mx-auto d-block" (click)="redirectToPaymentLink()">Comprar curso</button>
      </section>
    </article>
    <!-- FOR OTHER COUNTRIES - PAYMENT METHODS -->
    <article *ngIf="form.get('country').value?.name !== COUNTRIES.VENEZUELA && form.get('country').value?.name !== COUNTRIES.CHILE && form.get('country').value" class="mt-4 payment-method-content">
      <section>
        <p>
          Haz <strong>clic en el botón de abajo</strong> para ayudarte con tu proceso de inscripción:
        </p>
        <!-- WHATSAPP BUTTON -->
        <button class="btn btn-success mx-auto d-flex align-items-center" (click)="redirectToChat()">
          <span>Chatear con un agente</span> <img class="ml-2" src="assets/images/whatsapp-secondary-image.png" alt="whatsapp-image" width="35" height="35">
        </button>
      </section>
    </article>
    <div class="whatsapp-btn" (click)="redirectToChat()"></div>
  </section>
</article>
</section>
