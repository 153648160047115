import { Component } from '@angular/core';
import { teamList } from '../../core/constants/team.constants';
import { ITeam } from '../../core/constants/types.constants';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html'
})
export class TeamComponent {
  teamList: ITeam[] = teamList;
  constructor() { }

  openWindow(url: string): void {
    window.open(url, '_blank');
  }
}
