export function GTMScripts(key: string): void {
  const head = document.getElementsByTagName('head')[0];
  const body = document.getElementsByTagName('body')[0];

  const headerScript = document.createElement('script');
  headerScript.innerHTML = `
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${key}');`;
  

  const bodyScript = document.createElement('noscript');
  const frame = document.createElement('iframe');
  frame.src = `https://www.googletagmanager.com/ns.html?id=${key}`;
  frame.height = '0';
  frame.width  = '0';
  frame.style.cssText  = 'display:none;visibility:hidden';
  bodyScript.append(frame);

  head.insertBefore(headerScript, head.firstChild);
  body.insertBefore(bodyScript, body.firstChild);
}
