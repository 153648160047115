<div class="container-fluid px-4 recommendation-section">
    <hr>
    <div class="row">
        <!-- STEP BY STEP -->
        <div class="col">
            <h2 class="text-center mt-5 mb-5">¿Cómo aprovechar el curso al máximo?</h2>
            <div class="row justify-content-center">
                <div class="col-12 col-sm-8 col-md-6 col-lg-4 col-xl-3" *ngFor="let content of recommendationsInfo; let i = index">
                    <div class="recommendation-section-card recommendation-section-card-2">
                        <h3 class="font-weight-bold text-center text-light">
                            {{ content.title }}
                        </h3>
                        <img [src]="content.image" [alt]="content.alt" width="210" height="180" class="m-auto d-block">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>