<app-navbar></app-navbar>
<app-header></app-header>
<app-benefits></app-benefits>
<app-roadmap></app-roadmap>
<app-recommendation-section></app-recommendation-section>
<app-team></app-team>
<app-warranty></app-warranty>
<app-faq-section></app-faq-section>
<app-footer></app-footer>
<!-- WHATSAPP BUTTON -->
<!-- <div class="whatsapp-btn" (click)="redirectToChat()"></div> -->