import { CommonModule, TitleCasePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { StepService } from './services/step.service';

@NgModule({
  declarations: [
  ],
  imports: [
    HttpClientModule,
    CommonModule,
  ],
  exports: [
    HttpClientModule,
    CommonModule,
  ],
  providers: [
    TitleCasePipe,
    StepService
  ]
})
export class CoreModule { }​​​​​
