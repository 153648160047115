import { SwiperConfigInterface } from "ngx-swiper-wrapper";

export const SWIPER_ROADMAP_CONFIG: SwiperConfigInterface = {
    direction: 'horizontal',
    slideToClickedSlide: true,
    mousewheel: false,
    scrollbar: false,
    watchSlidesProgress: true,
    keyboard: true,
    slidesPerGroupSkip: 1,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    centeredSlides: false,
    loop: true,
    roundLengths: true,
    slidesOffsetBefore: 0,
    slidesOffsetAfter: 300,
    spaceBetween: 10,
    setWrapperSize: true,
    updateOnWindowResize: true,
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        initialSlide: 0,
        navigation: false

      },
      992: {
        slidesPerView: 3,
        initialSlide: 0,
        navigation: true
      },
    }
  };
  