<section class="container-fluid" id="benefits">
    <article class="row px-md-5">
        <section class="col mb-3">
            <article class="row justify-content-center">
                <section class="col-md-6 col-lg-3 my-auto d-none d-lg-flex">
                    <img src="assets/images/woman.svg" class="rounded-circle w-100" alt="happy-woman" width="318" height="318">
                </section>
                <section class="col col-lg-8 offset-lg-1">
                    <article class="row justify-content-center mt-5 my-md-5">
                        <section class="col">
                            <h3 class="text-center text-md-left">
                                Siendo parte del curso <strong>Programa Creativo: Primeros pasos en código</strong>
                            </h3>
                            <span class="d-block text-center text-md-left">
                                aprovecharás todo lo que este ofrece
                            </span>
                        </section>
                    </article>
                    <article class="row d-none d-md-flex">
                        <section class="col-6" *ngFor="let item of benefits">
                            <article class="row mb-5">
                                <section class="col col-md-4 col-lg-3 col-lg-2">
                                    <img [src]="item.image" [alt]="item.imageAlt" width="50" height="50" class="d-block ml-none mx-auto">
                                </section>
                                <section class="col col-md-8 col-lg-9">
                                    <article>
                                        <h2 class="benefit-title">
                                            <strong>
                                                {{item.title}}
                                            </strong>
                                        </h2>
                                        <p>
                                            {{item.paragraph}}
                                        </p>
                                    </article>
                                </section>
                            </article>
                        </section>
                    </article>
                    <article class="row d-md-none">
                        <section class="col">
                            <app-swiper [list]="benefits" [config]="swiperConfig" [swiperHeight]="swiperHeight"></app-swiper>
                        </section>
                    </article>
                </section>
            </article>
        </section>
    </article>
</section>