<div class="popup" id="popup" [hidden]="!closeButtonActive">
    <img src="assets/images/close.png" alt="close-btn" class="close-btn" (click)="closeButton()">
    <h6 class="mt-5 mb-3">Curso <strong>Programa Creativo: primeros pasos en código</strong></h6>
    <span class="mx-auto">23/10/2023</span>
    <button type="button" class="btn-go-to-next-section p-1 mt-3 btn-primary"
    (click)="redirectToRegisterCourseView()">
        <span>
            Inscribirme
        </span>
    </button>
</div>
