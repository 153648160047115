import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { roadMapList } from '../constants/roadmap.constants';
import { IStep } from '../constants/types.constants';

export class StepService {
  private readonly stepSubject = new BehaviorSubject<Array<IStep>>(roadMapList);
  public roadMapSteps = this.stepSubject.asObservable().pipe(distinctUntilChanged());
  private readonly actualStepSubject = new BehaviorSubject<IStep>(roadMapList[0]);
  public $actualstep = this.actualStepSubject.asObservable().pipe(distinctUntilChanged());

  constructor() { }

  get steps(): Array<IStep> {
    return this.stepSubject.value;
  }

  set steps(steps: Array<IStep>) {
    this.stepSubject.next(steps);
  }

  get actualStep(): IStep {
    return this.actualStepSubject.value;
  }

  set actualStep(step: IStep) {
    this.actualStepSubject.next(step);
  }

  /**
   * @param step paso(STEP)
   * @returns valida conkey del step que se le pase por parámetro y devuelve el step correcto
   */
  findStepByKey = (step: IStep): IStep => {
    return this.steps.find(item => item?.key === step.key);
  }
  /**
   * @param step paso(STEP)
   */
  setActualStep = (step: IStep): void => {
    this.changeActiveStepValue();
    this.actualStep = this.findStepByKey(step);
    this.actualStep.active = true;
  }

  changeActiveStepValue = (): void => {
    for (const i of this.steps) {
      if (i.active) {
        i.active = false;
      }
    }
  }
}
