import { IBenefits } from "./types.constants";

export const BENEFITS: Array<IBenefits> = [
    {
        title: 'Clases 100% en vivo',
        paragraph: 'Clases 100% en vivo con grupo de estudiantes acotados',
        image: 'assets/images/online-education.svg',
        imageAlt: 'online-classes-icon',
        swiperHeight: '70',
        imgClasses: 'swiper-img'
    },
    {
        title: 'App de desafíos',
        paragraph: 'App para consolidar lo aprendido en clases poniéndolo en práctica',
        image: 'assets/images/code-app.svg',
        imageAlt: 'path-icon',
        swiperHeight: '70',
        imgClasses: 'swiper-img'
    },
    {
        title: 'Material audiovisual',
        paragraph: `Videos para consolidar tus conocimientos`,
        image: 'assets/images/video.svg',
        imageAlt: 'video-icon',
        swiperHeight: '70',
        imgClasses: 'swiper-img'
    },
    {
        title: 'Dinámicas de feedback 1:1',
        paragraph: 'Dinámicas 1:1 para orientarte en tu carrera',
        image: 'assets/images/feedback.svg',
        imageAlt: 'feedback-icon',
        swiperHeight: '70',
        imgClasses: 'swiper-img'
    },
]