import { Component } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { SWIPER_ROADMAP_CONFIG } from 'src/app/core/constants/swiper.constants';
import { roadMapList } from '../../../app/core/constants/roadmap.constants';
import { StepService } from '../../../app/core/services/step.service';

@Component({
  selector: 'app-roadmap',
  templateUrl: './roadmap.component.html'
})
export class RoadmapComponent {
  roadMapList = roadMapList;
  swiperConfig: SwiperConfigInterface = SWIPER_ROADMAP_CONFIG;
  constructor(public readonly stepService: StepService) { }
}
