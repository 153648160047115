<div class="container-fluid px-4 mt-5" id="roadmap">
    <div class="row roadmap">
        <div class="col-12">
            <h2 class="mb-5 text-center">
                Y ¿qué aprenderé en el curso?
            </h2>
        </div>
        <div class="col-12">
            <div class="row justify-content-center">
                <!-- swiper -->
                <div class="col-12">
                    <!-- <div style="width: 100%;"><div style="position: relative; padding-bottom: 56.25%; padding-top: 0; height: 0;"><iframe frameborder="0" width="1200" height="675" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" src="https://view.genial.ly/621410580fcf4f0018b84c49" type="text/html" allowscriptaccess="always" allowfullscreen="true" scrolling="yes" allownetworking="all"></iframe> </div> </div> -->
                    <app-swiper [list]="roadMapList" [config]="swiperConfig"></app-swiper>
                </div>
            </div>
        </div>
    </div>
</div>
