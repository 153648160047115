<div class="container-fluid price-section py-4 mb-5">
    <div class="row justify-content-center">
        <div class="col-12 col-xl-7 d-flex justify-content-center mb-3 mb-xl-0">
            <!-- PRICE TEXT -->
            <section class="d-block d-md-inline-flex align-items-center">
                <article class="text-center">
                    <span>
                        Costo del curso: 
                    </span>
                </article>
                <article class="d-flex justify-content-center">
                    <span class="price ml-md-3">67.999 CLP</span> 
                    <img src="assets/images/chile.png" alt="chile-flag" width="20" height="20" class="ml-2 mr-md-3 align-self-center d-inline-block">
                </article>
                <span class="d-none d-md-inline-block">|</span>  
                <article class="d-flex justify-content-center">
                    <span class="price ml-md-3">85,68 USD</span>
                    <img src="assets/images/usa.png" alt="usa-flag" width="20" height="20" class="mx-2 align-self-center d-inline-block">
                </article>
            </section>
        </div>
        <div class="col-12 col-xl-5 d-flex justify-content-center">
            <button type="button" class="btn btn-light p-3 d-flex justify-content-center" (click)="redirectToPaymentView()">
                <p class="align-self-center m-0" id="buy-course-btn">
                    Inscribirme en el <strong>curso</strong>
                </p>
                <span class="icon icon-register my-2 ml-2 d-none d-md-block"></span>
            </button>
        </div>
    </div>
</div>
