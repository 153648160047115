import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AVAILABLE_COUNTRIES } from 'src/app/core/constants/countries.constants';
import { PAYMENT_METHODS, PAYMENT_METHODS_VE } from 'src/app/core/constants/payment-methods.constants';
import { COUNTRIES } from 'src/app/core/constants/types.constants';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payment-view',
  templateUrl: './payment-view.component.html'
})
export class PaymentViewComponent implements OnInit {
  countries = AVAILABLE_COUNTRIES;
  paymentMethods = PAYMENT_METHODS;
  paymentMethodsVe = PAYMENT_METHODS_VE;
	form: FormGroup;
	paymentMethodForm: FormGroup;
  COUNTRIES = COUNTRIES;
  url: any = environment.sdk.flow.payLink;

  constructor(private _formBuilder: FormBuilder, private router: Router) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = this._formBuilder.group({
      country: ['', Validators.required],
    })
  }

  initPaymentMethodForm() {
    this.paymentMethodForm = this._formBuilder.group({
      paymentMethod: ['']
    })
  }

  redirectToPaymentLink() {
    window.open(this.url, '_blank');
  }

  redirectToChat(): void {
    window.open(environment.sdk.whatsapp.link, '_blank');
  }

  goBack() {
    this.form.reset();
  }

  goHome() {
    this.router.navigate(['/'])
  }

}
