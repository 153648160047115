import { IRecommendation } from './types.constants';

export let RECOMMENDATIONS: Array<IRecommendation> = [
    {
        title: 'Asistiendo a todas las clases',
        image: 'assets/images/online-class.svg',
        alt: 'online-class-image-programa-creativo'
    },
    {
        title: 'Siendo autodidacta',
        image: 'assets/images/autodidacta.svg',
        alt: 'researching-programa-creativo'
    },
    {
        title: 'Preguntando a los mentores',
        image: 'assets/images/mentores.svg',
        alt: 'conversation-programa-creativo'
    },
];
